.teaching-stats-table {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}

.teaching-stats-table td {
  border: 1px solid;
  padding: 8px;
}

.teaching-stats-table thead {
  font-weight: 700;
  border: 1px solid;
  padding: 8px;
}

table {
  width: 100%;
  min-width: 1000px;
  box-sizing: border-box;
  border-collapse: collapse;
  text-align: center;
}

th {
  border: 1px solid;
  padding: 8px;
  text-align: center;
}

td {
  border: 1px solid;
  padding: 8px;
  text-align: center;
}

thead {
  font-weight: 700;
  border: 1px solid;
  padding: 8px;
  text-align: center;
}

/* Transcript column ratio */
th.transcript-year {
  width: 8%;
}
th.transcript-semester {
  width: 7%;
}
th.transcript-subject-code {
  width: 10%;
}
th.transcript-subject-name {
  width: 30%;
}
th.transcript-credits {
  width: 5%;
}
th.transcript-class {
  width: 5%;
}
th.transcript-score {
  width: 5%;
}
th.transcript-notes {
  width: 30%;
}