body {
  margin: 0;
  font-family:  'Segoe UI',"Segoe UI Web (West European)", 'Arimo', 'Helvetica Neue', 'Roboto', 'Fira Sans', 
  'Oxygen', 'Ubuntu', 'Cantarell', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
